import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';

export const StyledHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.navy,
  padding: theme.spacing(3),
  paddingLeft: '152px',
  paddingRight: '152px',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '25px',
    paddingRight: '25px',
  },
}));

export const StyledLogoBox = styled(Box)({
  display: 'inline-block',
  height: '22.4px',
  borderLeft: '2px solid white',
  marginLeft: '8px',
  marginRight: '8px',
  verticalAlign: 'middle',
});

export const StyledLink = styled(Link)(({ theme, color }) => ({
  textDecoration: 'none',
  color: color || theme.palette.common.white, 
  '& .MuiTypography-root': {
    color: color || theme.palette.common.white,
  }
}));

