import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import EditCandidateDetails from './EditCandidateDetails';
import EditCampaignDetails from './EditCampaignDetails';
import {
  BaseOrganizationPerson,
  BuyerDetail,
  BuyerOption,
  BuyerPerson,
} from '../../interfaces';
import { CampaignDetails, CandidateDetails } from './CampaignDetailsPage';

interface EditAboutSectionModalProps {
  open: boolean;
  handleClose: () => void;
  buyerDetails: BuyerDetail[];
  buyerPersons: BuyerPerson[];
  candidateDetails: CandidateDetails;
  updateDetails: Function;
  campaignDetails: CampaignDetails;
  buyerOptions: BuyerOption[]
}

const EditAboutSectionModal = ({
  open,
  handleClose,
  buyerDetails,
  buyerPersons,
  candidateDetails,
  updateDetails,
  campaignDetails,
  buyerOptions,
}: EditAboutSectionModalProps) => {
  const { id } = useParams();
  const { user } = useAuth0();

  const candidatePerson = buyerPersons.find(
    (person: BaseOrganizationPerson) => person.role.name === 'Candidate'
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{ '& .MuiDialog-paper': { maxHeight: '65vh' } }}
      disableScrollLock
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h6"> Campaign Information</Typography>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            <EditCandidateDetails
              user={user}
              candidatePerson={candidatePerson}
              candidateDetails={candidateDetails}
              updateDetails={updateDetails}
            />
          </Grid>
          <Grid container spacing={1}>
            <EditCampaignDetails
              buyerDetails={buyerDetails}
              updateDetails={updateDetails}
              campaignDetails={campaignDetails}
              user={user}
              id={id}
              buyerOptions={buyerOptions}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}></Grid>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}></DialogActions>
    </Dialog>
  );
};

export default EditAboutSectionModal;