import { useNavigate, useParams } from "react-router-dom";
import ReactFlowWrapper from "./ReactFlowViewProcesses";
import { getProcess } from "../../../queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import CircularProgressBackdropSmall from "../../common/CircularProgressBackdropSmall";
import { Box, Button, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import NodeInfoForm from "./NodeInfoForm";
import AddStepOrPhaseForm from "./AddSteporPhaseForm";
import { TabPanel } from "../../common/TabPanel";
import CopyFullPhaseForm from "./CopyFullPhaseForm";

interface NodeData {
  color: string;
  description: string;
  expandable: boolean;
  expanded: boolean;
  externalId: string;
  id: string;
  name: string;
  priority: number;
}
interface NodeSelected {
  id: string;
  data: NodeData
  measured: {
    width: number;
    height: number;
  };
  position: {
    x: number;
    y: number;
  };
  type: string
}

const ProcessPage = () => {
  const { processId } = useParams();
  const navigate = useNavigate();
  const [locked, setLocked] = useState(false);
  const [nodeSelectedData, setNodeSelectedData] = useState<NodeSelected | null>(null)
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, tabValue: number) => {
    setTabValue(tabValue);
  };

  
  const toggleLock = () => {
    setLocked((prevLocked) => !prevLocked);
  };

  const {
    loading: isQueryLoading,
    error,
    data: queryData,
  } = useQuery(getProcess, {
    variables: { id: Number(processId) },
  });

  if (isQueryLoading) {
    return <CircularProgressBackdropSmall />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error loading process
      </Typography>
    );
  }

  const process = _.get(queryData, 'process', {});
  const workflowItems = _.get(process, 'workflowItems', []);
  const phases = workflowItems.flatMap((item: any) => item.phases || []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          mt: 2,
          padding: 2,
        }}
      >
        <IconButton
          onClick={() => navigate('/superadmin/processes')}
          sx={{ color: 'navy' }}
          disableRipple
        >
          <ArrowBack />
        </IconButton>
        <Typography
          variant='overline'
          color='textPrimary'
          sx={{ ml: 1, cursor: 'pointer' }}
          onClick={() => navigate('/superadmin/processes')}
        >
          Back to Processes
        </Typography>
      </Box>

      <Grid container direction='column' sx={{ height: 650, padding: 2, mt: 0 }}>
        <Grid container item spacing={2} sx={{ height: 650 }}>
          <Grid item xs={4} sx={{ bgcolor: 'white' }}>
            <Tabs value={tabValue} onChange={handleChange} variant='fullWidth'>
              <Tab label='View or Edit Process Item' />
              <Tab label='Add a Step or Phase' />
              <Tab label='Copy Full Phase' />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Box sx={{ bgcolor: 'white' }}>
                {nodeSelectedData ? (
                  <NodeInfoForm nodeSelectedData={nodeSelectedData} />
                ) : (
                  <p>Please select a node to view details.</p>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box
                sx={{
                  bgcolor: 'white',
                  p: 2,
                  height: '100%',
                }}
              >
                <AddStepOrPhaseForm workflowItems={workflowItems} phases={phases} processId={Number(processId)} />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
            <CopyFullPhaseForm currentWorkflowItems={workflowItems} processId={Number(processId)}></CopyFullPhaseForm>
            </TabPanel>
          </Grid>

          <Grid item xs={8}>
            <Box sx={{ bgcolor: 'white', height: '100%', overflow: 'hidden' }}>
              <Button
                size='small'
                variant='outlined'
                onClick={toggleLock}
                style={{
                  color: locked ? 'gray' : 'navy',
                  borderColor: locked ? 'gray' : 'navy',
                }}
              >
                {locked ? 'Unlock Nodes' : 'Lock Nodes'}
              </Button>
              <ReactFlowWrapper
                process={process}
                setNodeSelectedData={setNodeSelectedData}
                locked={locked}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}


export default ProcessPage;
