import { gql } from '@apollo/client'


export const ADD_PROCESS_STEP = gql `
  mutation AddProcessStep($processStep: ProcessStepInput!) {
    addProcessStep(processStep: $processStep) {
      id
      name
      priority
    }
  }
`
export const UPDATE_PROCESS_STEP = gql `
  mutation updateProcessStep($processStep: ProcessStepUpdateInput!) {
    updateProcessStep(processStep: $processStep) {
    id
    description
    name
    priority 

      }
    }

`

export const ADD_PROCESS_PHASE = gql `
  mutation AddProcessPhase($processPhase: ProcessPhaseInput!) {
    addProcessPhase(processPhase: $processPhase) {
      id
      name
      description
      priority
      workflowItem {
        id
        name
      }
    }
  }
`

export const UPDATE_PROCESS_PHASE = gql `
  mutation updateProcessPhase($processPhase: ProcessPhaseUpdateInput!) {
    updateProcessPhase(processPhase: $processPhase) {
    id
    description
    name
    priority 
      }
    }
`

export const COPY_PROCESS_PHASE = gql `
  mutation createProcessPhase($workflowItemId: Int!, $processPhase: ProcessPhaseInput!) {
    createProcessPhase(workflowItemId: $workflowItemId, processPhase: $processPhase) {
    id name
    }
  }
`

