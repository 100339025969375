import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Key } from '@mui/icons-material';

const SuperAdminLogin = () => {
  const { 
    loginWithRedirect,
  } = useAuth0();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginWithRedirect({
      redirectUri: window.location.origin + '/superadmin/processes'
    });
  };


  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Box
        sx={{
          padding: 5,
          border: '1px solid #ccc',
          boxShadow: 2,
          textAlign: 'center',
          width: '300px',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Super Admin Page
        </Typography>
        <form onSubmit={handleSubmit}>
        <Button
          type="submit" 
          color="primary"
          size="medium"
          variant="contained"
          endIcon={<Key />}
          sx={{
            mb: 2,
            pl: 4,
            pr: 4,
            mt: '12px',
            color: '#FFFFFF',
            fontSize: '16px',
          }}
        >
          Login
        </Button>
      </form>
      </Box>
    </Grid>
  );
};

export default SuperAdminLogin;
