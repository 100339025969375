import { useState } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import CustomSnackbar from '../../common/CustomSnackbar';
import { allProcesses, getProcess } from '../../../queries';
import { useMutation, useQuery } from '@apollo/client';
import CircularProgressBackdropSmall from '../../common/CircularProgressBackdropSmall';
import { COPY_PROCESS_PHASE } from '../../../mutations/process';
import { Phase, Process, Step, WorkflowItem } from '../superadmin.types';

interface CopyFullPhaseFormProps {
  processId: number
  currentWorkflowItems: WorkflowItem[]
}

const CopyFullPhaseForm = ({ processId, currentWorkflowItems }: CopyFullPhaseFormProps) => {
  const [selectedProcessId, setSelectedProcessId] = useState('');
  const [selectedWorkflowItemId, setSelectedWorkflowItemId] = useState('');
  const [selectedPhaseId, setSelectedPhaseId] = useState('');
  const [selectedTargetWorkflowId, setSelectedTargetWorkflowId] = useState('');
  const [copyProcessPhase, ] = useMutation(COPY_PROCESS_PHASE, {
    refetchQueries: [
      {
        query: getProcess,
        variables: { id: Number(processId) },
      },
    ],
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    isSuccess: true,
  });

  const {
    loading: isQueryLoading,
    error,
    data: queryData,
  } = useQuery(allProcesses, {
    variables: { id: Number(processId) },
    skip: !processId,
  });

  if (isQueryLoading) {
    return <CircularProgressBackdropSmall />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error loading process
      </Typography>
    );
  }

  const processes = queryData?.processes || [];
  const selectedProcess = processes.find(
    (process: Process) => process.id === Number(selectedProcessId)
  );

  const workflowItems = selectedProcess?.workflowItems || [];

  const selectedWorkflowItem = workflowItems.find(
    (item: WorkflowItem) => item.id === Number(selectedWorkflowItemId)
  );
  const phases = selectedWorkflowItem?.phases || [];

  const handleCopy = async () => {
    if (!selectedPhaseId || !selectedTargetWorkflowId) {
      setSnackbarData({
        message: 'Please select all fields before proceeding!',
        isSuccess: false,
      });
      setSnackbarOpen(true);
      return;
    }

    const selectedPhase = selectedWorkflowItem.phases.find(
      (phase: Phase) => phase.id === Number(selectedPhaseId)
    );

    const processPhaseInput = {
      name: selectedPhase.name,
      description: selectedPhase.description || "",
      workflowItem: { id: selectedTargetWorkflowId },
      priority: selectedPhase.priority || 0,
      authorizeRole: selectedPhase.authorizeRole ? { id: selectedPhase.authorizeRole.id } : null,
       steps: selectedPhase.steps
        .map((step: Step) => ({
          name: step.name,
          description: step.description || "",
          phase: { id: step.phase.id },
          priority: step.priority || 0,
          stepType: { id: 4 },
          externalIds: step.externalIds?.map((externalId) => ({
            externalIdType: externalId.externalIdType,
            externalId: externalId.externalId,
          })) || [],
        })) || [],
    };

    await copyProcessPhase({
      variables: {
        workflowItemId: selectedTargetWorkflowId,
        processPhase: processPhaseInput,
      },
    });

    setSnackbarData({
      message: `Phase ${selectedPhaseId} copied to workflow ${selectedTargetWorkflowId} successfully!`,
      isSuccess: true,
    });
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h5">Copy an existing phase into a workflow</Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Process</InputLabel>
        <Select
          value={selectedProcessId}
          onChange={(e) => {
            setSelectedProcessId(e.target.value);
            setSelectedWorkflowItemId('');
            setSelectedPhaseId('');
            setSelectedTargetWorkflowId('');
          }}
        >
          {processes.map((process: Process) => (
            <MenuItem key={process.id} value={process.id}>
              {process.seller?.name + ': ' + process.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedProcessId}>
        <InputLabel>Select Workflow Item</InputLabel>
        <Select
          value={selectedWorkflowItemId}
          onChange={(e) => {
            setSelectedWorkflowItemId(e.target.value);
            setSelectedPhaseId('');
          }}
        >
          {workflowItems.map((item: WorkflowItem) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedWorkflowItemId}>
        <InputLabel>Select Phase to Copy</InputLabel>
        <Select
          value={selectedPhaseId}
          onChange={(e) => setSelectedPhaseId(e.target.value)}
        >
          {phases.map((phase: Phase) => (
            <MenuItem key={phase.id} value={phase.id}>
              {phase.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedPhaseId}>
        <InputLabel>Select Target Workflow</InputLabel>
        <Select
          value={selectedTargetWorkflowId}
          onChange={(e) => setSelectedTargetWorkflowId(e.target.value)}
        >
          {currentWorkflowItems.map((workflow: WorkflowItem) => (
            <MenuItem key={workflow.id} value={workflow.id}>
              {workflow.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        disabled={!selectedPhaseId || !selectedTargetWorkflowId}
        onClick={handleCopy}
      >
        Copy
      </Button>

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarData.message}
        onClose={handleCloseSnackbar}
        isSuccess={snackbarData.isSuccess}
      />
    </Box>
  );
};

export default CopyFullPhaseForm;