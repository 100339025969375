import { useState } from 'react';
import shortLogo from '../../staclabs_shortlogo.svg';
import { useTheme } from '@mui/material/styles';

import { Box, Divider, Drawer, Grid, IconButton, Typography, useMediaQuery} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { StyledHeader, StyledLogoBox, StyledLink } from '../common/StyledAdminHeader';


const AdminHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
  };

  return (
    <StyledHeader>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}
      >
        <Grid item xs>
          <StyledLink href="/admin" variant="h6" underline="none">
            <Box
              component="img"
              src={shortLogo}
              alt="logo"
              sx={{ height: 20, mr: 1 }}
            />
            <Typography variant="h5" component="span">
              DemLaunch
            </Typography>
            <StyledLogoBox />
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontWeight: 500,
                letterSpacing: 1,
                fontSize: '12.8px',
                verticalAlign: 'middle',
              }}
            >
              ADMIN
            </Typography>
          </StyledLink>
        </Grid>
        <Grid item>
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon sx={{ color: 'white' }} />
              </IconButton>

              <Drawer
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
                disableScrollLock
              >
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={toggleDrawer(false)}
                  disableScrollLock
                >
                  <Box sx={{ width: 250 }}>
                    <Grid
                      container
                      direction="column"
                      spacing={3}
                      sx={{ p: 3 }}
                    >
                      <Grid item>
                        <StyledLink href="/admin/campaigns" color={theme.palette.common.navy}>
                          <Typography variant="h6">All campaigns</Typography>
                        </StyledLink>
                      </Grid>
                      <Divider />

                      <Grid item>
                        <StyledLink href="" color={theme.palette.common.navy}>
                          <Typography variant="h6" color="inherit">
                            Knowledge center
                          </Typography>
                        </StyledLink>
                      </Grid>
                      <Divider />

                      <Grid item>
                        <StyledLink href="" color={theme.palette.common.navy}>
                          <Typography variant="h6" color="inherit">
                            Settings
                          </Typography>
                        </StyledLink>
                      </Grid>
                    </Grid>
                  </Box>
                </Drawer>
              </Drawer>
            </>
          ) : (
            <Grid
              container
              spacing={7}
              sx={{ ml: '20px', fontSize: '18px', flexWrap: 'nowrap' }}
            >
              <Grid item>
                <StyledLink href="/admin/campaigns">All campaigns</StyledLink>
              </Grid>
              <Grid item>
                <StyledLink href="">Knowledge center</StyledLink>
              </Grid>
              <Grid item>
                <StyledLink href="">Settings</StyledLink>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </StyledHeader>
  );
};

export default AdminHeader;