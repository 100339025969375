import { EditOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { MaskTextField } from '../common';
import { useLambdas } from '../../utils/lambdas';
import { CandidateDetails } from './CampaignDetailsPage';
import { User } from '@auth0/auth0-react';
import { BaseOrganizationPerson } from '../../interfaces';

interface EditCandidateDetailsProps {
  candidateDetails: CandidateDetails;
  updateDetails: Function;
  user?: User;
  candidatePerson?: BaseOrganizationPerson;
}

const EditCandidateDetails = ({
  candidateDetails,
  updateDetails,
  user,
  candidatePerson,
}: EditCandidateDetailsProps) => {
  const [isLockedMode, setIsLockedMode] = useState(false);
  const [localCandidateDetails, setLocalCandidateDetails] = useState({
    ...candidateDetails,
    id: candidatePerson?.person.id,
  });

  const formFields = [
    {
      label: 'First Name',
      name: 'firstName',
      value: localCandidateDetails.firstName,
    },
    {
      label: 'Middle Name',
      name: 'middleName',
      value: localCandidateDetails.middleName,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      value: localCandidateDetails.lastName,
    },
    { label: 'Email', name: 'email', value: localCandidateDetails.email },
    {
      label: 'Phone number',
      name: 'phone',
      value: localCandidateDetails.phone,
      isMasked: true,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setLocalCandidateDetails({
      ...localCandidateDetails,
      [name]: value,
    });
  };

  const [, executePost] = useLambdas('updatePerson', user, {
    person: localCandidateDetails,
  });

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await executePost();
      updateDetails(localCandidateDetails);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
    setIsLockedMode(true);
  };

  return (
    <Box
      sx={{ position: 'relative', maxWidth: 600, padding: 0, margin: '0 auto' }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, .5)',
            zIndex: 10,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: 600,
          padding: 1,
          margin: '0 auto',
        }}
      >
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Edit Candidate Details</Typography>
            <IconButton
              size="small"
              onClick={() => setIsLockedMode((prev) => !prev)}
            >
              <EditOutlined />
            </IconButton>
          </Grid>
        </Grid>

        {formFields.map((field) => (
          <Grid item xs={12} key={field.name}>
            {field.isMasked ? (
              <MaskTextField
                disabled={isLockedMode}
                fullWidth
                id={field.name}
                label={field.label}
                name={field.name}
                mask="(000) 000-0000"
                value={field.value}
                sx={{ width: '50%' }}
                onChange={handleLocalChange}
              />
            ) : (
              <TextField
                label={field.label}
                variant="outlined"
                fullWidth
                value={field.value}
                name={field.name}
                InputLabelProps={{ shrink: true }}
                sx={{ width: '50%' }}
                disabled={isLockedMode}
                onChange={handleLocalChange}
              />
            )}
          </Grid>
        ))}

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isLockedMode}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                disabled={isLockedMode}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditCandidateDetails;
