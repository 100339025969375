import { EditOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useLambdas } from '../../utils/lambdas';
import DateComponent from '../buyer/buyerOptions/Date';
import { User } from '@auth0/auth0-react';
import { BuyerOption, PersonBuyerOptionValues } from '../../interfaces';
import { CampaignDetails } from './CampaignDetailsPage';

interface Result {
  [key: string]: {
    detailValue?: string;
    optionValue?: string;
    detailId?: { id: number }; 
    optionId?: { id: number }; 
  };
}

type Detail = {
  key: string;
  id: number;
};

interface EditCampaignDetailsProps {
  campaignDetails: CampaignDetails;
  buyerDetails: any[];
  updateDetails: Function;
  user?: User;
  id?: string | number;
  buyerOptions: BuyerOption[]
}

const EditCampaignDetails = ({
  campaignDetails,
  buyerDetails,
  updateDetails,
  buyerOptions,
  user,
  id,
}: EditCampaignDetailsProps) => {
  const [isLockedMode, setIsLockedMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [details, setCampaignDetails] = useState(campaignDetails);
  const electionDateObject = buyerDetails.find(
    (buyerDetail) => buyerDetail.key === 'ElectionDate'
  );
  const formFields = [
    { label: 'Office', name: 'office', value: details.office },
    {
      label: 'Street Address',
      name: 'contactAddress',
      value: details.contactAddress,
    },
    { label: 'City', name: 'contactCity', value: details.contactCity },
    { label: 'State', name: 'contactState', value: details.contactState },
    { label: 'Zip', name: 'contactZip', value: details.contactZip },
    {
      label: 'Further Details',
      name: 'comments',
      value: details.comments,
      multiline: true,
      rows: 3,
    },
    { label: 'Website', name: 'website', value: details.website },
  ];

  const [existingDetailsPayload, setExistingDetailsPayload] = useState<Result>(
    {}
  );
  const [newDetailsPayload, setNewDetailsPayload] = useState<Result>({});

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCampaignDetails((prevFields: CampaignDetails) => ({
      ...prevFields,
      [name]: value,
    }));
    const capitalizedKey = name.charAt(0).toUpperCase() + name.slice(1);

    const matchingDetail = buyerDetails.find(
      (detail: Detail) => detail.key === capitalizedKey
    );

    if (matchingDetail) {
      setExistingDetailsPayload((prevPayload) => ({
        ...prevPayload,
        [capitalizedKey]: {
          detailValue: value,
          detailId: { id: matchingDetail.id },
        },
      }));
    } else {
      const matchingOption = buyerOptions.find(
        (option: BuyerOption) => option.key === capitalizedKey
      );

      if (matchingOption) {
        setNewDetailsPayload((prevPayload) => ({
          ...prevPayload,
          [capitalizedKey]: {
            optionValue: value,
            optionId: { id: matchingOption.id },
          },
        }));
      }
    }
  };

  const [, executePostExisting] = useLambdas(
    'updateBuyer',
    user,
    {
      buyerId: Number(id),
      existingBuyerDetails: existingDetailsPayload,
    },
    true
  );

  const [, executePostNew] = useLambdas(
    'updateBuyer',
    user,
    {
      buyerId: Number(id),
      newBuyerDetails: newDetailsPayload,
    },
    true
  );

  const handleDateChange = (name: string, value: string, id: number) => {
    setCampaignDetails((prevFields: CampaignDetails) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (Object.keys(existingDetailsPayload).length > 0) {
        await executePostExisting();
      }
      if (Object.keys(newDetailsPayload).length > 0) {
        await executePostNew();
      }
      updateDetails(null, details);
      setExistingDetailsPayload({});
      setNewDetailsPayload({});
      setIsLockedMode(true);
    } catch (error) {
      console.error('Error executing Lambda:', error);
    }
    setIsLoading(false);
  };

  return (
    <Box
      sx={{ position: 'relative', maxWidth: 600, padding: 0, margin: '0 auto' }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, .5)',
            zIndex: 10,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Grid
        container
        spacing={2}
        sx={{ maxWidth: 600, padding: 1, margin: '0 auto' }}
      >
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Edit Campaign Details</Typography>
            <IconButton
              size="small"
              onClick={() => setIsLockedMode((prev) => !prev)}
            >
              <EditOutlined />
            </IconButton>
          </Grid>
        </Grid>

        {formFields.map((field) => (
          <Grid item xs={12} key={field.name}>
            <TextField
              label={field.label}
              variant="outlined"
              fullWidth
              name={field.name}
              value={field.value}
              InputLabelProps={{ shrink: true }}
              sx={{ width: '50%' }}
              onChange={handleFieldChange}
              disabled={isLockedMode}
              multiline={field.multiline || false}
              rows={field.rows || 1}
            />
          </Grid>
        ))}

        {/* Special handling for DateComponent */}
        <Grid item xs={6} sx={{ width: '50%' }}>
          <DateComponent
            optionKey={'electionDate'}
            label={'Election Date'}
            id={0}
            currentValue={details.electionDate}
            value={details.electionDate as unknown as PersonBuyerOptionValues}
            description={''}
            inputType={''}
            uiElement={''}
            uiOrder={0}
            isRequired={false}
            onDetailChange={handleDateChange}
            isDisabled={isLockedMode}
            detailType={electionDateObject.option.detailType}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isLockedMode}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                disabled={isLockedMode}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditCampaignDetails;
