import { Transaction } from "../../interfaces";

export interface GoodLastTransaction {
  id: number
  name: string
  transaction: Transaction | null
  goodDescriptionStatusText ?: string
}

export interface GroupedTransactions {
  [goodName: string]: Transaction[]
}

export function formatDate(dateString: string | Date | undefined): string {
  if (!dateString) return '';

  const date = typeof dateString === 'string' ? new Date(dateString) : dateString;

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date);
}


const GOOD_DISPLAY_TRIAGE_STATUS_TEXT = 'There seems to be an issue with this request. Please check details on Jira';
const THIRD_PARTY_IN_PROGRESS_TEXT = 'There is a request for this third party good';
const GOOD_DISPLAY_CANCELLED_STATUS_TEXT = 'This request was canceled';


const GOOD_INITIAL_APPROVAL_TEXT = "You've approved this request! We're still waiting on payment and or a contract before they'll be ready for setup";

const GOOD_POST_APPROVAL_TEXT = "This request is ready for fulfillment. Add additional details on Jira";

const GOOD_WAITING_FOR_PAYMENT_TEXT = 'This committee is on track to fulfill their payment plan';


// These arrays below represent workflowItems.names and when in progress are matches to a text above. 
const GOOD_INITIAL_APPROVAL_ARRAY = ['Handle Refundable Campaign Contract', 'VAN Account Setup (Refundable)',
'VAN Account Setup (In-Kind)', 'Refundable Received', 'VAN Committee Setup', 'VoteBuilder Committee Setup', 'VoteBuilder Committee Setup - Non-Federal',
'VAN Account Setup', 'In-Kind Received','VAN Account Setup (State Leg)', 'Handle State Leg Contract', 'State Party Approval', 'Open', 'Mobilize Account Setup']

const GOOD_POST_APPROVAL_ARRAY = ['Contract Complete', 'Campaign Signature', 'Send Contract via HelloSign', 'Bi-Payment Handle Contracts and First Payment',
  'Handle Contracts', 'Handle In-Kind Contract','Handle Contracts - CC Federal', 'Handle Contracts - CC Non-Federal', 'Documents Sent']

const GOOD_WAITING_FOR_PAYMENT_ARRAY = ['Documents Received', 'CC - Documents Sent Payment Plan', 'Primary Payment Due', 'General Election Payment Due', 'Payment is Due', 'Handle payment',
'Payment and VAN Account Setup', 'Second payment', 'Post-payment email (no second payment)', 'Handle Payment and Permissions', 'Stripe payment received', 'Initial Invoice and Payment', 'Payment is Due']


export const GOOD_DISPLAY_COMPLETE_STATUS_TEXT = 'This request has been fulfilled'

export const workflowItemStatusMappings = {
  [GOOD_INITIAL_APPROVAL_TEXT] : GOOD_INITIAL_APPROVAL_ARRAY,

  [GOOD_POST_APPROVAL_TEXT]: GOOD_POST_APPROVAL_ARRAY,

  [GOOD_WAITING_FOR_PAYMENT_TEXT] : GOOD_WAITING_FOR_PAYMENT_ARRAY,
};


export function filterGoodsByStatus(goods: GoodLastTransaction[]): GoodLastTransaction[] {
  goods.forEach((good: GoodLastTransaction) => {
    let statusText = ''

    if (good.transaction) {
      const { transaction } = good
      if (transaction.status === 'Complete') {
        good.goodDescriptionStatusText = GOOD_DISPLAY_COMPLETE_STATUS_TEXT;
      }
      else if (transaction.status === 'Triage') {
        good.goodDescriptionStatusText = GOOD_DISPLAY_TRIAGE_STATUS_TEXT;
      }
      else if (transaction.status === 'Canceled') {
        good.goodDescriptionStatusText = GOOD_DISPLAY_CANCELLED_STATUS_TEXT;
      }
      // Third-Party Good
      else if (transaction.status === 'InProgress' && transaction.externalId.externalId === 'NOT_CONFIGURED') {
        good.goodDescriptionStatusText = THIRD_PARTY_IN_PROGRESS_TEXT;
      }
      // If a workflow status is InProgress append to my set, so we don't get duplicated Text Statuses when concatenated 
      else if (transaction.status === 'InProgress') {
        const { workflowItems } = transaction;
        workflowItems.forEach((workflowItem) => {
          if (workflowItem.status === 'InProgress') {
            const appendedKeys = new Set<string>();
            Object.entries(workflowItemStatusMappings).forEach(([key, valueArray]) => {
              if (valueArray.includes(workflowItem.name)) {
                if (!appendedKeys.has(key)) {
                  statusText += key + '. ';
                  appendedKeys.add(key);
                }
              }
            });
          }
        });
        good.goodDescriptionStatusText = statusText
      }
    }
  });
  return goods;
}