import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../../store';
import { useAuth0 } from '@auth0/auth0-react';
import { getAccount } from '../../queries';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import WarningDialog from '../common/WarningDialog';
import CircularProgressBackdropSmall from '../common/CircularProgressBackdropSmall';


const SuperAdminRoutes = () => {
  const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
  const updateRefetch = useGlobalStore((state) => state.updateRefetch);
  const updateAccount = useGlobalStore((state) => state.updateAccount);
  const navigate = useNavigate();
  const [openWarning, setOpenWarning] = useState(false);

  const email = user?.email || '';

  const { loading: isAccountLoading, data: accountData, refetch } = useQuery(
    getAccount,
    {
      variables: { email: email },
      skip: !email,
    }
  );

  useEffect(() => {
    if (authLoading || isAccountLoading) {
      return;
    }

    if (isAuthenticated && user && email && accountData) {
      updateAccount(accountData);
      updateRefetch(refetch);

      const sellers = _.get(accountData, 'account.person.sellers', []);
      const hasSuperUserPermission = _.some(sellers, (seller) => {
        const isSuperuser = _.get(seller, 'permissionGroup.isSuperuser', false);
        return isSuperuser
      });

      if (!hasSuperUserPermission) {
        setOpenWarning(true);
      }
    } else {
      navigate('/superadmin');
    }
  }, [isAuthenticated, user, email, accountData, authLoading, isAccountLoading, updateAccount, updateRefetch, refetch, navigate]);

  const handleCloseWarning = () => {
    setOpenWarning(false);
    navigate('/superadmin');
  };

  const loading = authLoading || isAccountLoading

  if (loading) {
    return (
      <CircularProgressBackdropSmall/>
    );
  }

  if (openWarning) {
    return (
      <WarningDialog open={openWarning} onClose={handleCloseWarning} />
    );
  }

  return <Outlet />;
};

export default SuperAdminRoutes;
