import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';

export const SuperadminStyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '1px solid #CECECE !important',
  color: theme.palette.text.primary,
  textAlign: 'center',
}));

export const SuperadminTableHeaderTypography = styled(Typography)({
  fontSize: '19px',
  fontWeight: 500, 
});