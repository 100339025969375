import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { getToolTransactions } from '../../queries';
import {
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ArrowBack, EditOutlined } from '@mui/icons-material';
import DetailsToolsSection from './DetailsToolsSection';
import DetailsAboutSection from './DetailsAboutSection';
import CircularProgressBackdropSmall from '../common/CircularProgressBackdropSmall';
import { BuyerDetail, BuyerOption, Transaction } from '../../interfaces';
import {
  GoodLastTransaction,
  GroupedTransactions,
  filterGoodsByStatus,
  formatDate,
} from './utils';
import { TabPanel } from '../common/TabPanel';
import { useEffect, useState } from 'react';
import { theme } from '../../utils/theme';
import { useLambdas } from '../../utils/lambdas';
import { useAuth0 } from '@auth0/auth0-react';
import EditAboutSectionModal from './EditAboutSectionModal';

interface Good {
  id: number;
  name: string;
  message?: string;
}

interface GoodObject {
  good: Good;
  usage: string;
  message: string;
}

const NOT_APPLICABLE = 'N/A';

interface AboutSectionFields {
  [key: string]: string;
}

export interface CandidateDetails {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface CampaignDetails {
  office: string;
  electionDate: string;
  contactAddress: string;
  contactCity: string;
  contactState: string;
  contactZip: string;
  comments: string;
  website: string;
}

export interface AboutDetails {
  'Full Name': string;
  Email: string;
  Phone: string;
  Office: string;
  'Election Date': string;
  Address: string;
  Website: string;
  'Further Details': string;
}

export type AboutSectionPartial = Partial<AboutDetails>;

function getVisibleGoods(goods: GoodObject[]): { id: number; name: string }[] {
  const goodsWithoutMessage = goods
    .filter((item) => {
      if (!item.message) {
        return true;
      }
      return false;
    })
    .map((item) => ({
      id: item.good.id,
      name: item.good.name,
    }));

  return goodsWithoutMessage;
}

function combineAndSortGoodsAndTransactions(
  goods: Good[],
  mostRecentGoodTransactions: { [key: string]: Transaction }
): GoodLastTransaction[] {
  const combineGoodsAndTransactions = goods.map((good) => {
    const matchingTransaction = Object.values(mostRecentGoodTransactions).find(
      (transaction: Transaction) =>
        transaction.process.bundle &&
        transaction.process.bundle.goods[0].id === good.id
    );

    return {
      ...good,
      transaction: matchingTransaction || null,
    };
  });

  const missingGoods = Object.values(mostRecentGoodTransactions)
    .filter(
      (transaction: Transaction) =>
        !goods.some(
          (good: Good) =>
            transaction.process.bundle &&
            transaction.process.bundle.goods[0].id === good.id
        )
    )
    .map((transaction: Transaction) => ({
      id: transaction.process.bundle.goods[0].id,
      name: transaction.process.bundle.goods[0].name,
      transaction,
    }));

  return [...combineGoodsAndTransactions, ...missingGoods].sort((a, b) => {
    if (!a.transaction && b.transaction) return 1;
    if (a.transaction && !b.transaction) return -1;
    return 0;
  });
}

function groupTransactionsByGoodName(
  transactions: Transaction[]
): GroupedTransactions {
  return transactions.reduce(
    (acc: GroupedTransactions, transaction: Transaction) => {
      const goodName = transaction.process.bundle.goods[0].name;

      if (!acc[goodName]) {
        acc[goodName] = [];
      }

      acc[goodName].push(transaction);
      return acc;
    },
    {}
  );
}

function getMostRecentGoodTransaction(
  groupedTransactions: GroupedTransactions
): { [goodName: string]: Transaction } {
  const highestIdTransactions: { [goodName: string]: Transaction } = {};

  Object.keys(groupedTransactions).forEach((goodName: string) => {
    const transactions = groupedTransactions[goodName];

    const highestTransaction = transactions.reduce(
      (prev: Transaction, current: Transaction) =>
        prev.id > current.id ? prev : current
    );

    highestIdTransactions[goodName] = highestTransaction;
  });

  return highestIdTransactions;
}

const CampaignDetailsPage = () => {
  const { id } = useParams();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const isMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const [aboutDetails, setAboutDetails] = useState<AboutSectionFields>({});

  const handleChange = (event: React.SyntheticEvent, tabValue: number) => {
    setTabValue(tabValue);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [candidateDetails, setCandidateDetails] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [campaignDetails, setCampaignDetails] = useState({
    office: '',
    electionDate: '',
    contactAddress: '',
    contactCity: '',
    contactState: '',
    contactZip: '',
    comments: '',
    website: '',
  });

  const { loading: isQueryLoading, data: buyerData } = useQuery(
    getToolTransactions,
    {
      variables: { id: Number(id) },
    }
  );

  const buyer = buyerData?.buyer || {};
  const { buyerDetails, buyerPersons, transactions, sellers } = buyer;

  const templateId = _.get(buyerDetails, '[0].option.templateId', '0');

  const [
    {
      data: potentialGoodsForBuyer = { goods: [] },
      loading: arePotentialGoodsLoading,
    },
  ] = useLambdas(
    'getBuyerPotentialGoods',
    user,
    {
      templateId: templateId,
      buyerId: id,
    },
    false
  );
  useEffect(() => {
    if (buyerDetails) {
      const candidate = _.find(
        buyerPersons,
        (person) => person.role && person.role.name === 'Candidate'
      );

      const firstName = candidate?.person?.firstName || '';
      const middleName = candidate?.person?.middleName || '';
      const lastName = candidate?.person?.lastName || '';
      const email = candidate?.person?.email || '';
      const phone = candidate?.person?.phone || '';

      setCandidateDetails({
        firstName,
        middleName,
        lastName,
        email,
        phone,
      });

      const fullName = candidate
        ? `${candidate.person?.firstName || ''} ${
            candidate.person?.lastName || ''
          }`
        : NOT_APPLICABLE;

      const keysToExtract = [
        'Office',
        'website',
        'ElectionDate',
        'ContactAddress',
        'ContactCity',
        'ContactState',
        'ContactZip',
        'Website',
        'Comments',
      ];

      const extractedFields = buyerDetails.reduce(
        (acc: { [key: string]: string }, detail: BuyerDetail) => {
          if (
            detail.key &&
            keysToExtract.includes(detail.key) &&
            typeof detail.key === 'string'
          ) {
            acc[detail.key] =
              detail.value !== undefined ? detail.value : NOT_APPLICABLE;
          }
          return acc;
        },
        {}
      );

      const initialAboutDetails: AboutSectionFields = {
        'Full Name': fullName,
        Email: email,
        Phone: phone,
        Office: extractedFields['Office'] || '',
        'Election Date': formatDate(extractedFields['ElectionDate']),
        Address: `${extractedFields['ContactAddress'] || ''}, ${
          extractedFields['ContactCity'] || ''
        }, ${extractedFields['ContactState'] || ''}, ${
          extractedFields['ContactZip'] || ''
        }`,
        Website: extractedFields['Website'] || '',
        'Further Details': extractedFields['Comments'],
      };

      setCampaignDetails({
        office: extractedFields['Office'] || '',
        electionDate: extractedFields['ElectionDate'] || '',
        contactAddress: extractedFields['ContactAddress'] || '',
        contactCity: extractedFields['ContactCity'] || '',
        contactState: extractedFields['ContactState'] || '',
        contactZip: extractedFields['ContactZip'] || '',
        comments: extractedFields['Comments'] || '',
        website: extractedFields['Website'] || '',
      });

      setAboutDetails(initialAboutDetails);
    }
  }, [buyerDetails, buyerPersons]);

  if (isQueryLoading || arePotentialGoodsLoading) {
    return <CircularProgressBackdropSmall />;
  }

  const updateDetails = (
    updatedCandidateDetails?: CandidateDetails,
    updatedCampaignDetails?: CampaignDetails
  ) => {
    if (updatedCandidateDetails) {
      setCandidateDetails(updatedCandidateDetails);

      setAboutDetails((prevAboutDetails: AboutSectionFields) => ({
        ...prevAboutDetails,
        'Full Name': `${updatedCandidateDetails.firstName} ${updatedCandidateDetails.lastName}`,
        Email: updatedCandidateDetails.email,
        Phone: updatedCandidateDetails.phone,
        Office: prevAboutDetails['Office'],
        'Election Date': prevAboutDetails['Election Date'],
        Address: prevAboutDetails['Address'],
        Website: prevAboutDetails['Website'],
        'Further Details': prevAboutDetails['Further Details'],
      }));
    }

    if (updatedCampaignDetails) {
      const {
        office,
        electionDate,
        contactAddress,
        contactCity,
        contactZip,
        contactState,
        website,
        comments,
      } = updatedCampaignDetails;

      setCampaignDetails(updatedCampaignDetails);

      setAboutDetails((prevAboutDetails: AboutSectionPartial) => ({
        ...prevAboutDetails,
        ...(office && { Office: office }),
        ...(electionDate && { 'Election Date': formatDate(electionDate) }),
        ...((contactAddress || contactCity || contactZip) && {
          Address: `${contactAddress || ''}, ${
            contactCity || ''
          }, ${contactState}, ${contactZip || ''}`,
        }),
        ...(website && { Website: website }),
        ...(comments && { 'Further Details': comments }),
      }));
    }
  };

  let mutableBuyerDetails = [...buyerDetails];
  mutableBuyerDetails = mutableBuyerDetails.map((detail) => {
    if (detail.key === 'Comments') {
      return {
        ...detail,
        option: {
          ...detail.option,
          label: 'Further Details',
        },
      };
    }
    return detail;
  });

  const buyerOptions:BuyerOption[] = sellers[0].optionTemplates[0].buyerOptions

  // maybe add useMemo in the future, if there are hundreds of goods and hundreds of transactions.
  const visibleGoods = getVisibleGoods(potentialGoodsForBuyer.goods);
  const groupedTransactions = groupTransactionsByGoodName(transactions);
  const mostRecentGoodTransaction =
    getMostRecentGoodTransaction(groupedTransactions);
  const goodsToDisplay = combineAndSortGoodsAndTransactions(
    visibleGoods,
    mostRecentGoodTransaction
  );
  const goodsToDisplayWithDescriptionStatusText =
    filterGoodsByStatus(goodsToDisplay);

  return (
    <Grid container direction="column" spacing={2} sx={{ padding: '20px' }}>
      <Grid item>
        <Box
          onClick={() => navigate('/admin/campaigns')}
          sx={{ cursor: 'pointer' }}
        >
          <Grid container alignItems="center" spacing={0}>
            <Grid item>
              <IconButton
                sx={{ color: theme.palette.common.navy }}
                disableRipple
              >
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item sx={{ marginTop: '2px' }}>
              <Typography variant="overline" color="textPrimary">
                ALL CAMPAIGNS
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography variant="h3" color="textPrimary" sx={{ marginTop: '10px' }}>
          {buyerData.buyer.name}
        </Typography>
      </Grid>

      {isMediumBreakpoint ? (
        <>
          <Grid item>
            <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
              <Tab label="Tools" />
              <Tab label="About" />
            </Tabs>
          </Grid>

          <TabPanel value={tabValue} index={0}>
            <Box
              sx={{
                padding: '12px 4px 0',
              }}
            >
              <Grid item xs={12}>
                {goodsToDisplayWithDescriptionStatusText &&
                  goodsToDisplayWithDescriptionStatusText.length > 0 &&
                  goodsToDisplayWithDescriptionStatusText.map(
                    (goodToDisplay, index: number) => {
                      const { name, transaction, goodDescriptionStatusText } =
                        goodToDisplay;
                      return (
                        <DetailsToolsSection
                          key={index}
                          name={name}
                          transaction={transaction}
                          goodDisplayDescription={goodDescriptionStatusText}
                        />
                      );
                    }
                  )}
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ padding: '20px 46px 0' }}
              >
                <Grid item>
                  <Typography variant="h4">About</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleOpen}>
                    <EditOutlined color="primary" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <DetailsAboutSection
                  buyerDetails={buyerDetails}
                  buyerPersons={buyerPersons}
                  aboutSectionFields={aboutDetails}
                />
              </Grid>
            </Grid>
          </TabPanel>

          {open && (
            <EditAboutSectionModal
              open={open}
              handleClose={handleClose}
              buyerDetails={mutableBuyerDetails}
              buyerPersons={buyerPersons}
              candidateDetails={candidateDetails}
              updateDetails={updateDetails}
              campaignDetails={campaignDetails}
              buyerOptions={buyerOptions}
            />
          )}
        </>
      ) : (
        <Grid item container spacing={3} sx={{ marginTop: '10px' }}>
          {/* Tools Section */}
          <Grid item xs={12} md={7}>
            <Typography variant="h4" sx={{ marginBottom: '20px' }}>
              Tools
            </Typography>
            {goodsToDisplayWithDescriptionStatusText &&
              goodsToDisplayWithDescriptionStatusText.length > 0 &&
              goodsToDisplayWithDescriptionStatusText.map(
                (goodToDisplay, index: number) => {
                  const { name, transaction, goodDescriptionStatusText } =
                    goodToDisplay;
                  return (
                    <DetailsToolsSection
                      key={index}
                      name={name}
                      transaction={transaction}
                      goodDisplayDescription={goodDescriptionStatusText}
                    />
                  );
                }
              )}
          </Grid>
          {/* About Section */}
          <Grid item xs={12} md={5}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                  About
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <IconButton sx={{ marginBottom: '20px' }} onClick={handleOpen}>
                  <EditOutlined color="primary" />
                </IconButton>
              </Grid>
            </Grid>
            <DetailsAboutSection
              buyerDetails={buyerDetails}
              buyerPersons={buyerPersons}
              aboutSectionFields={aboutDetails}
            />
          </Grid>
          {open && (
            <EditAboutSectionModal
              open={open}
              handleClose={handleClose}
              buyerDetails={mutableBuyerDetails}
              buyerPersons={buyerPersons}
              candidateDetails={candidateDetails}
              updateDetails={updateDetails}
              campaignDetails={campaignDetails}
              buyerOptions={buyerOptions}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default CampaignDetailsPage;
