import { Typography, Grid, useMediaQuery } from '@mui/material';
import { BuyerDetail, BuyerPerson } from '../../interfaces';
import { theme } from '../../utils/theme';
import { AboutDetails, AboutSectionPartial } from './CampaignDetailsPage';

interface AboutProps {
  buyerDetails: BuyerDetail[];
  buyerPersons: BuyerPerson[];
  aboutSectionFields: AboutSectionPartial;
}

const DetailsAboutSection = (props: AboutProps) => {
  const { aboutSectionFields } = props;
  const isMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: '455px',
        ...(isMediumBreakpoint && {
          margin: '16px',
          padding: '16px',
        }),
      }}
    >
      {Object.entries(aboutSectionFields as AboutDetails).map(
        ([label, value]) => (
          <Grid item xs={12} key={label}>
            <Typography
              variant="overline"
              color="textSecondary"
              sx={{
                margin: 0,
                padding: 0,
                lineHeight: '1.1',
                letterSpacing: 'normal',
              }}
            >
              {label.toUpperCase()}
            </Typography>
            <Typography variant="body1" sx={{ margin: 0 }}>
              {value !== '' && value !== null && value !== undefined
                ? value
                : `No ${label}`}
            </Typography>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default DetailsAboutSection;
