import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Collapse,
  IconButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ADD_PROCESS_PHASE, ADD_PROCESS_STEP } from "../../../mutations/process";
import { useMutation } from "@apollo/client";
import { getProcess } from "../../../queries";
import CustomSnackbar from "../../common/CustomSnackbar";
import { ExternalIdType } from "../superadmin.types";

interface AddStepOrPhaseFormProps {
  workflowItems: {
    id: number;
    name: string;
    description?: string;
  }[];
  phases: {
    id: number;
    name: string;
    description?: string;
  }[];
  processId: number;
}

type ProcessItem = {
  workflowItem?: {
    id?: string;
  };
  phase?: {
    id?: string;
  };
  name?: string;
  priority?: number;
  stepType?: {
    id?: number;
  };
  description?: string | null;
  externalIds?: {
    externalIdType?: string;
    externalId?: string;
  }[];
};

const AddStepOrPhaseForm = ({ workflowItems, phases, processId }: AddStepOrPhaseFormProps) => {
  const [type, setType] = useState('');
  const [parentId, setParentId] = useState('');
  const [stepTypeId, setStepTypeId] = useState('');
  const [isOptionalOpen, setIsOptionalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    isSuccess: true,
  });

  const [processItemDetails, setProcessItemDetails] = useState({
    name: '',
    priority: '',
    description: '',
    externalIdType: '',
    externalId: '',
  });

  const [addProcessPhase] = useMutation(ADD_PROCESS_PHASE, {
    refetchQueries: [
      {
        query: getProcess,
        variables: { id: Number(processId) },
      },
    ],
  });

  const [addProcessStep] = useMutation(ADD_PROCESS_STEP, {
    refetchQueries: [
      {
        query: getProcess,
        variables: { id: Number(processId) },
      },
    ],
  });

  const handleTypeChange = (value: string) => {
    setType(value);
    setProcessItemDetails({
      name: '',
      priority: '',
      description: '',
      externalIdType: '',
      externalId: '',
    });
  };

  const handleInputChange = (field: string, value: string) => {
    setProcessItemDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const toggleOptionalFields = () => {
    setIsOptionalOpen((prev) => !prev);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const submitProcessItem = async (type: string, processItem: ProcessItem) => {
    try {
      switch (type) {
        case 'phase':
          await addProcessPhase({ variables: { processPhase: processItem } });
          break;

        case 'step':
          await addProcessStep({ variables: { processStep: processItem } });
          break;

        default:
          throw new Error(`Unknown type: ${type}`);
      }

      setSnackbarData({
        message: `${type.charAt(0).toUpperCase() + type.slice(1)} added successfully!`,
        isSuccess: true,
      });
    } catch (error) {
      setSnackbarData({
        message: `Failed to add the ${type}. Please try again.`,
        isSuccess: false,
      });
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async () => {
    let processItem = {};

    if (type === 'phase') {
      processItem = {
        workflowItem: {
          id: parentId,
        },
        name: processItemDetails.name,
        priority: Number(processItemDetails.priority),
      };
    } else if (type === 'step') {
      processItem = {
        phase: {
          id: parentId,
        },
        name: processItemDetails.name,
        priority: Number(processItemDetails.priority),
        stepType: {
          id: Number(stepTypeId),
        },
        description: processItemDetails.description || null,
        externalIds: processItemDetails.externalIdType && processItemDetails.externalId
          ? [
            {
              externalIdType: processItemDetails.externalIdType,
              externalId: processItemDetails.externalId,
            },
          ]
          : [],
      };
    } else {
      return;
    }
    await submitProcessItem(type, processItem);
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant='h5'>Add a Phase or Step</Typography>

      <Typography variant='subtitle1'>What would you like to add?</Typography>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Button
          sx={{ mr: 1 }}
          variant={type === 'phase' ? 'contained' : 'outlined'}
          onClick={() => handleTypeChange('phase')}
        >
          Add Phase
        </Button>
        <Button
          variant={type === 'step' ? 'contained' : 'outlined'}
          onClick={() => handleTypeChange('step')}
        >
          Add Step
        </Button>
      </Box>

      {type && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>
            {type === 'phase' ? 'Select Parent Workflow' : 'Select Parent Phase'}
          </InputLabel>
          <Select value={parentId} onChange={(e) => setParentId(e.target.value)}>
            {(type === 'phase' ? workflowItems : phases).map((parent) => (
              <MenuItem key={parent.id} value={parent.id}>
                {parent.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {parentId && (
        <>
          <Typography variant='subtitle1'>
            Enter {type === 'phase' ? 'Phase' : 'Step'} Details
          </Typography>
          <TextField
            label='Name'
            value={processItemDetails.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label='Priority'
            type='number'
            value={processItemDetails.priority}
            onChange={(e) => handleInputChange('priority', e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          {type === 'step' && (
            <TextField
              label='Step Type ID'
              value={stepTypeId}
              onChange={(e) => setStepTypeId(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              mt: 2,
              mb: 2,
            }}
            onClick={toggleOptionalFields}
          >
            <Typography variant='subtitle1' sx={{ flexGrow: 1 }}>
              Optional Fields
            </Typography>
            <IconButton>
              {isOptionalOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>

          <Collapse in={isOptionalOpen}>
            <Box
              sx={{
                mb: 2,
                mt: 2,
                border: '1px solid #ccc',
                padding: 2,
                borderRadius: 2,
              }}
            >
              <TextField
                label='Description'
                value={processItemDetails.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              {type === 'step' && (
                <>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>External ID Type</InputLabel>
                    <Select
                      value={processItemDetails.externalIdType}
                      onChange={(e) =>
                        handleInputChange('externalIdType', e.target.value)
                      }
                    >
                      {Object.entries(ExternalIdType).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label='External ID'
                    value={processItemDetails.externalId}
                    onChange={(e) =>
                      handleInputChange('externalId', e.target.value)
                    }
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                </>
              )}
            </Box>
          </Collapse>

          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleSubmit}
          >
            Add {type === 'phase' ? 'Phase' : 'Step'}
          </Button>
        </>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarData.message}
        onClose={handleCloseSnackbar}
        isSuccess={snackbarData.isSuccess}
      />
    </Box>
  );
};

export default AddStepOrPhaseForm;